/* Ensure the side panels are sticky */
.side-panel {
  position: sticky;
  top: 0;
  height: 100vh;
  overflow-y: auto;
  min-width: 20%;
}

/* Ensure the main content doesn't overlap the side panels */
.main-content {
  overflow-x: hidden;
  padding-left: 15px; /* Adjust as needed */
  padding-right: 15px; /* Adjust as needed */
}


/* remove sidebars for smallscreen */
@media (max-width: 575.98px) {
  .min-width-sm {
    display: none;
  }
}


/* article titles */
.custom-link {
  color: inherit; /* Ensures the link inherits the color of the h4 */
}

.custom-link:hover h4 {
  color: rgb(90, 5, 5); /* Change this to your desired hover color */
}

.title-red {
  color: red;
}


/* tab category */
.active-tab {
  background-color: red !important;
  color: white !important;
}



.custom-jumbotron {
  background: linear-gradient(135deg, #ff1c20, #f84949);
  padding: 0.5rem 0.5rem;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.custom-heading {
  font-family: 'Arial', sans-serif;
  font-weight: bold;
  color: #fff;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
}